
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WaterfallChartHandler from '@/components/reports-v2/components/elements/charts/WaterfallChartHandler.vue';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import BaseFilterable from './BaseFilterable.vue';

@Component({
	components: {
		WaterfallChartHandler,
		DataInfo,
		BaseFilterable,
	},
})
export default class WaterfallChartFilterable extends BaseFilterableContainer {
	@Prop({ default: () => [] }) public readonly xAxisData!: string[];
	@Prop({ default: () => [] }) public readonly xAxisValue!: number[];
	@Prop({ default: () => [] }) public readonly dataColor!: string[];
	@Prop({ default: () => [] }) public readonly barType!: string[];

	public get printedTitle() {
		return `${this.title} - ${this.dateFormatted}`;
	}
}
