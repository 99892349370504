
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
    DimensionData,
    DimensionMap,
    PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
    components: {
        AmountDisplayerFilterable,
    },
})
export default class TotalRevenueNumberDisplayer extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['transactions'];
    }

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'projects'
    > = [];

    public amount: number = 0;

    public get accountStartingPeriodThisYear(): [number, number] {
        return [gdbx.accountStartingDateThisYear, moment().endOf('day').valueOf()];
    }

    public get expensiveHook() {
        const {
            selectedAsOfDate,
            selectedDateRange,
            accountStartingPeriodThisYear,
            selectedProjects,
        } = this;
        return JSON.stringify([
            selectedAsOfDate,
            selectedDateRange,
            accountStartingPeriodThisYear,
            selectedProjects,
        ]);
    }

    public async expensiveCalc() {
      const ref = FilteredDatabase.ref('transactions')
              .dateRange(this.selectedDateRange)
              .includes('project', this.selectedProjects);

        const incomeAccTypeList: string[] = ['SL', 'SA', 'OI', 'EO'];
        const incomeAccTypeNameList: string[] = [
            'SALES',
            'SALES ADJUSTMENT',
            'OTHER INCOME',
            '',
        ];

        const incomeMap: DimensionMap[] = incomeAccTypeList.map((code, index) => ({
            filterType: 'string',
            filterKey: 'accType',
            value: [code],
            text: incomeAccTypeNameList[index],
        }));

        const incomeDatas: DimensionData[] = await this._loadDimension(
            ref,
            incomeMap,
            1,
            0,
            'amount',
        );

        const netSales = Math.abs(
            incomeDatas
                .filter((dd) => dd.value[0] === 'SL' || dd.value[0] === 'SA')
                .reduce((a, b) => a + b.sum, 0),
        );

        const otherIncomes = (
            incomeDatas
                .filter((dd) => dd.value[0] === 'OI' || dd.value[0] === 'EO')
                .reduce((a, b) => a + b.sum, 0)
        );

        this.amount = otherIncomes * -1;

        this.saveHistory('amount');
    }
}
