
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import moment, { Moment } from 'moment';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class NetProfitBeforeTaxNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
    | 'projects'
	> = ['dateAsOf'];

	public amount: number = 0;

	public get monthValues() {
		const [d0, d1] = this.selectedDateRange.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		const result: Moment[] = [];
		for (let i = 0; i < period; i++) {
			result.push(d0.clone());
			d0.add(1, 'month');
		}
		return result;
	}

	public get accountStartingPeriodThisYear(): [number, number] {
		return [gdbx.accountStartingDateThisYear, moment().endOf('day').valueOf()];
	}

	public get expensiveHook() {
		const {
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
      selectedProjects,
		} = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
      selectedProjects,
		]);
	}

    public openingStockDateRange(dateRange: [number, number]): [number, number] {
        return [
            moment(dateRange[0]).add(-1, 'month').valueOf(),
            moment(dateRange[1]).add(-1, 'month').valueOf(),
        ];
    }

	public async expensiveCalc() {
        const netProfitRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA', 'CO', 'OI', 'EO', 'EP']);

        const openingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.openingStockDateRange(this.selectedDateRange));

        const closingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.selectedDateRange);

        let pa = 0.33;
        let pb = 0.33;

        const netProfit = await this._loadDimensionByPeriod(
            netProfitRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.33;

        const openingStocks = await this._loadDimensionByPeriod(
            openingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.33;

        const closingStocks = await this._loadDimensionByPeriod(
            closingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const netProfitTotal = netProfit.reduce((a, b) => a + b.sum, 0);
        const openingStockTotal = openingStocks.reduce((a, b) => a + b.sum, 0);
        const closingStockTotal = closingStocks.reduce((a, b) => a + b.sum, 0);

        this.amount = netProfitTotal * -1 - openingStockTotal + closingStockTotal;

		this.saveHistory('amount');
	}
}
