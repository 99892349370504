
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { DimensionMap, PermissionsGroup, ValueText } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';

@Component({
	components: {
		SecondaryLineBarChartFilterable,
		DateFilter,
		SelectFilter,
	},
})
export default class ProfitAndLossComparisonMixedChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'compareDate1'
		| 'compareDate2'
		| 'transactions'
	> = ['compareDate1', 'compareDate2', 'transactions'];

	public xAxisData: string[] = [];
	public series: echarts.ECharts[] | any[] = [];

	public selectedDate1 = moment().add(-1, 'year').startOf('year').valueOf();
	public selectedDate2 = moment().startOf('year').valueOf();
	public selectedType: string = 'sales';

	public typeOptions: ValueText[] = [
		{ text: 'Sales', value: 'sales' },
		{ text: 'Cost of Goods Sold', value: 'cost' },
		{ text: 'Expenses', value: 'expense' },
	];

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get yearNames() {
		return [
			moment(this.selectedDate1).year(),
			moment(this.selectedDate2).year(),
		];
	}

	public get dataViewFormatter() {
		return DataViewFormatter.salesTracker;
	}

	public get expensiveHook() {
		const { selectedDate1, selectedDate2, selectedType } = this;
		return JSON.stringify([selectedDate1, selectedDate2, selectedType]);
	}

	public async expensiveCalc() {
		const year1Ref = FilteredDatabase.ref('transactions')
			.year(this.yearNames[0])
			.includes(
				'accType',
				this.selectedType === 'cost'
					? ['CO']
					: this.selectedType === 'expense'
					? ['EP']
					: ['SL', 'SA', 'OI', 'EO'],
			);
		const year2Ref = FilteredDatabase.ref('transactions')
			.year(this.yearNames[1])
			.includes(
				'accType',
				this.selectedType === 'cost'
					? ['CO']
					: this.selectedType === 'expense'
					? ['EP']
					: ['SL', 'SA', 'OI', 'EO'],
			);

		let pa = 0.5;
		let pb = 0;

		const year1DD = await this._loadDimensionByPeriod(
			year1Ref,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.5;

		const year2DD = await this._loadDimensionByPeriod(
			year2Ref,
			'month',
			pa,
			pb,
			'amount',
		);

		const year1IsThisYear = this.yearNames[0] === moment().year();
		const year2IsThisYear = this.yearNames[1] === moment().year();

		const year1MonthIndex = year1IsThisYear ? moment().month() + 1 : 12;
		const year2MonthIndex = year2IsThisYear ? moment().month() + 1 : 12;

		const year1Datas = year1DD.map((dd) => dd.sum);
		const year2Datas = year2DD.map((dd) => dd.sum);

		const invisibleMax: number[] = [];
		const accumulatedResults1: number[] = [];
		const accumulatedResults2: number[] = [];

		for (let i = 0; i < year1MonthIndex; i++) {
			const object = { value: 0, itemStyle: { color: '' } };
			// To insert invisible data to push up the max value of secondary axis
			invisibleMax[i] = Math.max(...year1Datas) + Math.max(...year2Datas);
			if (i === 0) {
				accumulatedResults1[i] = year1Datas[i];
			} else {
				accumulatedResults1[i] = year1Datas[i] + accumulatedResults1[i - 1];
			}
		}

		for (let i = 0; i < year2MonthIndex; i++) {
			const object = { value: 0, itemStyle: { color: '' } };
			if (i === 0) {
				accumulatedResults2[i] = year2Datas[i];
			} else {
				accumulatedResults2[i] = year2Datas[i] + accumulatedResults2[i - 1];
			}
		}

		const dataYear1: echarts.EChartsCoreOption | any = {
			name: this.selectedType + this.yearNames[0],
			type: 'bar',
			barWidth: '30%',
			itemStyle: { color: GobiColor.TEAL },
			data: year1Datas,
		};

		const dataYear2: echarts.EChartsCoreOption | any = {
			name: this.selectedType + this.yearNames[1],
			type: 'bar',
			itemStyle: { color: GobiColor.YELLOW },
			barWidth: '30%',
			data: year2Datas,
		};

		const invisibleDatas: echarts.EChartsCoreOption | any = {
			name: '',
			type: 'bar',
			itemStyle: { opacity: 0 },
			barWidth: '1%',
			data: { value: invisibleMax, label: { show: false } },
		};

		const accumulatedDataYear1: echarts.EChartsCoreOption | any = {
			name: 'Cumulative' + this.selectedType + this.yearNames[0],
			type: 'line',
			itemStyle: { color: GobiColor.DEEP_TEAL },
			smooth: true,
			yAxisIndex: 1,
			data: accumulatedResults1,
		};

		const accumulatedDataYear2: echarts.EChartsCoreOption | any = {
			name: 'Cumulative' + this.selectedType + this.yearNames[1],
			type: 'line',
			itemStyle: { color: GobiColor.ORANGE },
			smooth: true,
			yAxisIndex: 1,
			data: accumulatedResults2,
		};

		this.xAxisData = year1DD
			.map((dd) => dd.text)
			.map((month) => month.split(' '))
			.map((splitMonth) => splitMonth[0]);

		this.series = [];
		this.series = [
			dataYear1,
			dataYear2,
			accumulatedDataYear1,
			accumulatedDataYear2,
			invisibleDatas,
		];

		this.saveHistory('series', 'xAxisData');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
