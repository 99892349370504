
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	DimensionData,
	DimensionMap,
	TransactionCard,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import moment from 'moment';

@Component({
	components: {
		TableFilterable,
		TableCollapseToggle,
	},
})
export default class ExpensesTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
    | 'projects'
	> = ['dateRange'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
	public periodTotalData: Array<{ month: number; total: number }> = [];
	public periodTotalFields: any[] = [];

	public get exportFileName() {
		const formattedDate = [
			moment(this.selectedDateRange[0]).format('DD MMM YY'),
			moment(this.selectedDateRange[1]).format('DD MMM YY'),
		];
		return (
			'Expenses' + '_' + formattedDate[0] + ' to ' + formattedDate[1]
		);
	}

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
      selectedDateRange,
      selectedProjects,
    } = this;
		return JSON.stringify([
      selectedDateRange,
      selectedProjects,
    ]);
	}

	public async expensiveCalc() {
    const ref = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects);

		let pa = 0.2;
		let pb = 0;

		const refHead = await ref.get();

		pb += pa;
		pa = 0.15;

		let cards: TransactionCard[] = await refHead.getCards();

		cards = cards.filter((item) => item.accType === 'EP');

		const specialAccTypeCodeList = [
			...new Map(
				cards.map((item) => [
					item.specialAccTypeCode!,
					item.specialAccTypeName,
				]),
			).entries(),
		];

		const specialAccTypeCodeMap: DimensionMap[] = specialAccTypeCodeList.map(
			(item) => ({
				filterType: 'string',
				filterKey: 'specialAccTypeCode',
				value: [item[0]],
				text: item[1],
			}),
		);

		pb += pa;
		pa = 0.15;

		const monthDatas: DimensionData[] = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
		);

		pb += pa;
		pa = 0.15;

		const specialAccTypeDatas: DimensionData[] = await this._loadDimension(
			ref,
			specialAccTypeCodeMap,
			pa,
			pb,
		);

		this.tableFields = [
			{ key: 'particular', sortable: true, stickyColumn: true },
			{
				key: 'total',
				sortable: true,
				formatter: TableItemFormatter.currency,
			},
			...monthDatas.map((dd) => ({
				key: dd.text,
				sortable: true,
				formatter: TableItemFormatter.currency,
			})),
		];

		this.periodTotalFields = [
			{ key: 'month', sortable: true, formatter: TableItemFormatter.date2 },
			{ key: 'total', sortable: true, formatter: TableItemFormatter.currency },
		];

		pb += pa;
		pa = 0.15;

		const datas = await this._loadDimension(
			ref,
			specialAccTypeCodeMap,
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, monthDatas, paa, pbb, 'amount'),
		);

		pb += pa;
		pa = 0.15;

		const datas2 = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			(reff, paa, pbb) =>
				this._loadDimension(reff, specialAccTypeDatas, paa, pbb, 'amount'),
		);

		this.generateLoadingText(1);

		const periodTotalData: Array<{ month: number; total: number[] }> = [];

		const cloneDatas2 = [...datas2];
		const subDDList = cloneDatas2.map((datas2dd) => datas2dd!.subDimension);

		this.tableItems = datas
			// .filter((dd) => dd.sum !== 0)
			.map((dd, index) => {
				const item = {
					particular: dd.text,
					total: dd.sum,
					_cellVariants: {},
				};
				let i = 0;

				dd.subDimension!.forEach((sd, sdIndex) => {
					i++;
					const result = subDDList[sdIndex]!.sort((a, b) => b.sum - a.sum)
						.filter((subdd) => subdd.sum !== 0)
						.slice(0, 5)
						.map((sumListItem) => sumListItem.sum);

					const includeTopFive = result.includes(sd.sum);

					item[sd.text] = sd.sum;
					item._cellVariants[sd.text] = includeTopFive ? 'top-five' : '';

					const subItem: any = { month: sd.value[0][1], total: [] };

					if (periodTotalData[sdIndex]) {
						if (i < 1) {
							periodTotalData[sdIndex].month = sd.value[0][1] as number;
						}
						periodTotalData[sdIndex].total[index] = roundToFixed(
							sd.sum,
							this.numDecimal,
						);
					} else {
						periodTotalData[sdIndex] = subItem;
						if (i < 1) {
							periodTotalData[sdIndex].month = sd.value[0][1] as number;
						}
						periodTotalData[sdIndex].total[index] = roundToFixed(
							sd.sum,
							this.numDecimal,
						);
					}
				});
				return item;
			});

		this.periodTotalData = periodTotalData.map((item) => ({
			month: item.month,
			total: item.total.reduce((a, b) => a + b, 0),
		}));

		this.saveHistory(
			'tableItems',
			'tableFields',
			'periodTotalData',
			'periodTotalFields',
		);
	}
}
