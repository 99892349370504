
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalRevenueNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
    | 'project'
	> = [];

	public amount: number = 0;

	public get accountStartingPeriodThisYear(): [number, number] {
		return [gdbx.accountStartingDateThisYear, moment().endOf('day').valueOf()];
	}

	public get expensiveHook() {
		const {
      selectedProjects,
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
		} = this;
		return JSON.stringify([
      selectedProjects,
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
		]);
	}

	public async expensiveCalc() {
        const netSalesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA']);

    const incomeAccTypeList: string[] = [
      'SL',
      'SA',
      'OI',
      'EO',
    ];
		const incomeAccTypeNameList: string[] = [
			'SALES',
			'SALES ADJUSTMENT',
		];

    const incomeMap: DimensionMap[] = incomeAccTypeList.map((code, index) => ({
			filterType: 'string',
			filterKey: 'accType',
			value: [code],
			text: incomeAccTypeNameList[index],
		}));

        const pb = 0.1;
        const pa = 0.1;

        const netSales = await this._loadDimensionByPeriod(
            netSalesRef,
            'month',
            pa,
            pb,
            'amount',
        );

		this.amount = Math.abs(Math.abs(
            netSales.reduce((a, b) => {
                return a + b.sum;
            }, 0) * -1,
        ));

		this.saveHistory('amount');
	}
}
