
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import * as echarts from 'echarts';
import StackedBarChartFilterable from '@/components/reports-v2/components/filterables/StackedBarChartFilterable.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
    DimensionData,
    DimensionMap,
    PermissionsGroup,
} from '@/store/models.def';
import {GobiColor} from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import Button from 'primevue/button';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import {TableItemFormatter} from '../../elements/charts/helpers/tableItemFormatter';
import moment from 'moment/moment';

@Component({
    components: {
        StackedBarChartFilterable,
        TableCollapseToggle,
        Button,
        SelectFilter,
    },
})
export default class SalesPurchaseExpenseBarChart extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['transactions'];
    }

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'projects'
    > = ['dateRange'];

    public xAxisData: string[] = [];
    public series: echarts.ECharts[] | any = [];
    public periodTotalData: Array<{
        net_sales: number;
        net_purchases: number;
        expenses: number;
    }> = [];
    public periodTotalFields: any[] = [];
    public isConfirmApply: number = 0;

    public selectedTimeframe: string = this.timeframe[0];

    public get timeframe() {
        const timeframeList = [
            'Monthly',
            '3 months',
        ];
        return timeframeList;
    }

    public get currencySymbol() {
        return gdbx.currencySymbol;
    }

    public get expensiveHook() {
        const {
            isConfirmApply,
            selectedDateRange,
            selectedAsOfDate,
            selectedProjects,
        } = this;
        return JSON.stringify([
            isConfirmApply,
            selectedDateRange,
            selectedAsOfDate,
            selectedProjects,
        ]);
    }

    public async expensiveCalc() {
        const codeList = ['SL', 'SA', 'CO', 'EP'];
        const nameList = [
            'SALES',
            'SALES ADJUSTMENT',
            'COST OF GOODS SOLD',
            'EXPENSES',
        ];

        const map: DimensionMap[] = codeList.map((code, index) => ({
            filterType: 'string',
            filterKey: 'accType',
            value: [code],
            text: nameList[index],
        }));


        const netSalesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA']);

        const cogsRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('accTypeName', ['COST OF GOODS SOLD'])
            .includes('project', this.selectedProjects);

        const expensesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('accTypeName', ['EXPENSES'])
            .includes('project', this.selectedProjects);

        let pa = 0.33;
        let pb = 0;

        const salesDD = await this._loadDimensionByPeriod(
            netSalesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.33;

        const cogsDD = await this._loadDimensionByPeriod(
            cogsRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.33;

        const expensesDD = await this._loadDimensionByPeriod(
            expensesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        this.periodTotalFields = [
            {
                key: 'net_sales',
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'net_purchases',
                formatter: TableItemFormatter.currency,
            },
            {
                key: 'expenses',
                formatter: TableItemFormatter.currency,
            },
        ];

        const netSales = {
            name: 'Net Sales',
            data: [] as number[],
            type: 'bar',
            barWidth: '25%',
            barGap: '30%',
            large: true,
            smooth: true,
        };

        const cost = {
            name: 'Net Purchases',
            data: [] as number[],
            type: 'bar',
            barWidth: '25%',
            barGap: '30%',
            stack: 'spent',
            itemStyle: {color: GobiColor.COLOR3},
            smooth: true,
        };

        const expenses = {
            name: 'Expenses',
            data: [] as number[],
            type: 'bar',
            barWidth: '25%',
            barGap: '30%',
            stack: 'spent',
            itemStyle: {color: GobiColor.COLOR5},
            smooth: true,
        };

        this.xAxisData = [];

        netSales.data = salesDD.reverse().map((sales, index) => {
            return sales.sum * -1;
        });

        cost.data = cogsDD.reverse().map((cogs, index) => {
            return cogs.sum;
        });

        expenses.data = expensesDD.reverse().map((expense, index) => {
            return expense.sum;
        });

        this.xAxisData = salesDD.map((a) => {
            return a.text;
        });

        if (this.selectedTimeframe === '3 months') {
            const newArray1: any = [];
            const newArray2: any = [];
            const newArray3: any = [];

            newArray1.push(
                Math.abs(
                    (salesDD[0].sum)
                    + (salesDD[1].sum)
                    + (salesDD[2].sum),
                ),
            );

            newArray1.push(
                Math.abs(
                    (salesDD[3].sum)
                    + (salesDD[4].sum)
                    + (salesDD[5].sum),
                ),
            );

            newArray1.push(
                Math.abs(
                    (salesDD[6].sum)
                    + (salesDD[7].sum)
                    + (salesDD[8].sum),
                ),
            );

            newArray1.push(
                Math.abs(
                    (salesDD[9].sum)
                    + (salesDD[10].sum)
                    + (salesDD[11].sum),
                ),
            );

            newArray2.push(
                (cogsDD[0].sum)
                + (cogsDD[1].sum)
                + (cogsDD[2].sum),
            );

            newArray2.push(
                (cogsDD[3].sum)
                + (cogsDD[4].sum)
                + (cogsDD[5].sum),
            );

            newArray2.push(
                (cogsDD[6].sum)
                + (cogsDD[7].sum)
                + (cogsDD[8].sum),
            );

            newArray2.push(
                (cogsDD[9].sum)
                + (cogsDD[10].sum)
                + (cogsDD[11].sum),
            );

            newArray3.push(
                (expensesDD[0].sum)
                + (expensesDD[1].sum)
                + (expensesDD[2].sum),
            );

            newArray3.push(
                (expensesDD[3].sum)
                + (expensesDD[4].sum)
                + (expensesDD[5].sum),
            );

            newArray3.push(
                (expensesDD[6].sum)
                + (expensesDD[7].sum)
                + (expensesDD[8].sum),
            );

            newArray3.push(
                (expensesDD[9].sum)
                + (expensesDD[10].sum)
                + (expensesDD[11].sum),
            );

            netSales.data = newArray1;
            cost.data = newArray2;
            expenses.data = newArray3;

            this.xAxisData = [
                `Q4`,
                'Q3',
                'Q2',
                'Q1',
            ];
        }

        if (this.selectedTimeframe === '6 months') {
            const newArray1: any = [];
            const newArray2: any = [];
            const newArray3: any = [];

            newArray1.push(
                Math.abs(
                    (salesDD[0].sum)
                    + (salesDD[1].sum)
                    + (salesDD[2].sum)
                    + (salesDD[3].sum)
                    + (salesDD[4].sum)
                    + (salesDD[5].sum),
                ),
            );

            newArray1.push(
                Math.abs(
                    (salesDD[6].sum)
                    + (salesDD[7].sum)
                    + (salesDD[8].sum)
                    + (salesDD[9].sum)
                    + (salesDD[10].sum)
                    + (salesDD[11].sum),
                ),
            );

            newArray2.push(
                (cogsDD[0].sum)
                + (cogsDD[1].sum)
                + (cogsDD[2].sum)
                + (cogsDD[3].sum)
                + (cogsDD[4].sum)
                + (cogsDD[5].sum),
            );

            newArray2.push(
                (cogsDD[6].sum)
                + (cogsDD[7].sum)
                + (cogsDD[8].sum)
                + (cogsDD[9].sum)
                + (cogsDD[10].sum)
                + (cogsDD[11].sum),
            );

            newArray3.push(
                (expensesDD[0].sum)
                + (expensesDD[1].sum)
                + (expensesDD[2].sum)
                + (expensesDD[3].sum)
                + (expensesDD[4].sum)
                + (expensesDD[5].sum),
            );

            newArray3.push(
                (expensesDD[6].sum)
                + (expensesDD[7].sum)
                + (expensesDD[8].sum)
                + (expensesDD[9].sum)
                + (expensesDD[10].sum)
                + (expensesDD[11].sum),
            );

            netSales.data = newArray1;
            cost.data = newArray2;
            expenses.data = newArray3;

            this.xAxisData = [
                '2nd half',
                '1st half',
            ];
        }

        this.periodTotalData = [
            {
                net_sales: netSales.data.reduce((a, b) => a + b, 0),
                net_purchases: cost.data.reduce((a, b) => a + b, 0),
                expenses: expenses.data.reduce((a, b) => a + b, 0),
            },
        ];

        this.series = [netSales, cost, expenses];

        this.saveHistory(
            'series',
            'xAxisData',
            'periodTotalData',
            'periodTotalFields',
        );
    }

    public onHovering(value: boolean, filterId: string) {
        this.$emit('hovering', value, filterId);
    }

    public onEditing(value: boolean, filterId: string) {
        this.$emit('editing', value, filterId);
    }

    public applyFilter() {
        this.isConfirmApply += 1;
    }
}
