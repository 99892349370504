
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
    PermissionsGroup,
} from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import moment, {MomentInput} from 'moment/moment';
import {nestedGroupBy} from '@/util/nestedGroupBy';

@Component({
    components: {
        TableFilterable,
    },
})
export default class MonthToMonthComparison extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['transactions'];
    }

    public get dateFormatted() {
        return this.selectedDateRange;
    }

    public get expensiveHook() {
        const {
            selectedProjects,
            selectedAsOfDate,
            selectedDateRange,
        } = this;
        return JSON.stringify([
            selectedProjects,
            selectedAsOfDate,
            selectedDateRange,
        ]);
    }

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'project'
    > = [];

    public tableItems: any[] = [];

    public tableFields: any[] = [];
    public detailFields: any[] = [];

    public exportName(range: number[]) {
        return 'P&L Monthly Breakdown' + ' from ' +
            moment(range[0] as MomentInput).format('DD MMM YY') +
            ' - ' +
            moment(range[1] as MomentInput).format('DD MMM YY');
    }

    public getMonthYearArray(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);

        const monthYearArray: string[] = [];

        while (start.isBefore(end) || start.isSame(end, 'month')) {
            monthYearArray.push(start.format('MMM_YY'));
            start.add(1, 'month');
        }
        return monthYearArray;
    }

    public openingStockDateRange(dateRange: [number, number]): [number, number] {
        return [
            moment(dateRange[0]).add(-1, 'month').valueOf(),
            moment(dateRange[1]).add(-1, 'month').valueOf(),
        ];
    }

    public async expensiveCalc() {
        this.tableItems =  [];
        this.detailFields = [];
        this.tableFields = [];
        const newTable: any[] = [];
        const netSalesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA']);

        const salesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL']);

        const salesAdjRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SA']);

        const COGSRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['CO']);

        const grossProfitRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA', 'CO']);

        const otherIncomeRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['OI']);

        const extraOrdinaryIncomeRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['EO']);

        const expensesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['EP']);

        const netProfitRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA', 'CO', 'OI', 'EO', 'EP']);

        const openingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.openingStockDateRange(this.selectedDateRange));

        const closingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.selectedDateRange);

        const allMonthYears = this.getMonthYearArray(this.selectedDateRange[0], this.selectedDateRange[1]);

        this.generateLoadingText(1);

        let pb = 0.1;
        let pa = 0.1;

        const netSales = await this._loadDimensionByPeriod(
            netSalesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const sales = await this._loadDimensionByPeriod(
            salesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const salesAdjustment = await this._loadDimensionByPeriod(
            salesAdjRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const COGS = await this._loadDimensionByPeriod(
            COGSRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const grossProfit = await this._loadDimensionByPeriod(
            grossProfitRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const otherIncome = await this._loadDimensionByPeriod(
            otherIncomeRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const extraOrdinaryIncome = await this._loadDimensionByPeriod(
            extraOrdinaryIncomeRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const expenses = await this._loadDimensionByPeriod(
            expensesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const netProfit = await this._loadDimensionByPeriod(
            netProfitRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const openingStocks = await this._loadDimensionByPeriod(
            openingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const closingStocks = await this._loadDimensionByPeriod(
            closingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        // Sales
        const saleObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        saleObj.particular = 'Sales';

        saleObj.total = sales.reduce((a, b) => {
            return a + (b.sum);
        }, 0) * -1;

        sales.forEach((s) => {
            saleObj[(s.text).replace(/\s+/g, '_')] = s.sum * -1;
        });

        const groupedSL = nestedGroupBy(
            await (await salesRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedSL) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedSL[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0) * -1;

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedSL[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0) * -1;
                });

                saleObj.items.push(detailedObj);
            }
        }

        newTable.push(saleObj);

        // Sales Adjustment
        const salesAdjustmentObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        salesAdjustmentObj.particular = 'Sales Adjustment';

        salesAdjustmentObj.total = salesAdjustment.reduce((a, b) => {
            return a + b.sum;
        }, 0) * -1;

        salesAdjustment.forEach((s) => {
            salesAdjustmentObj[(s.text).replace(/\s+/g, '_')] = s.sum * -1;
        });

        const groupedSA = nestedGroupBy(
            await (await salesAdjRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedSA) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedSA[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0);

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedSA[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0);
                });

                salesAdjustmentObj.items.push(detailedObj);
            }
        }

        newTable.push(salesAdjustmentObj);


        // Net Sales
        const netSalesObj: {
            total: number,
            particular?: string | undefined,
        } = {
            total: 0,
        };

        netSalesObj.particular = 'Net Sales';

        netSalesObj.total = netSales.reduce((a, b) => {
            return a + b.sum;
        }, 0) * -1;

        netSales.forEach((s) => {
            netSalesObj[(s.text).replace(/\s+/g, '_')] = s.sum * -1 as number;
        });

        newTable.push(netSalesObj);

        // COGS
        const COGSObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        COGSObj.particular = 'COGS';

        COGSObj.total = COGS.reduce((a, b) => {
            return a + b.sum;
        }, 0);

        COGS.forEach((s) => {
            COGSObj[(s.text).replace(/\s+/g, '_')] = s.sum;

            const openingObj = openingStocks.find((card) => {
                return card.text
                    === moment('15 ' + s.text.replace('_', ' ')).add(-1, 'month').format('MMM YY');
            });

            const closingObj = closingStocks.find((card) => {
                return card.text === s.text;
            });

            if (openingObj) {
                COGSObj[(s.text).replace(/\s+/g, '_')] += openingObj.sum;
            }

            if (closingObj) {
                COGSObj[(s.text).replace(/\s+/g, '_')] -= closingObj.sum;
            }
        });

        const groupedCOGS = nestedGroupBy(
            await (await COGSRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedCOGS) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedCOGS[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0);

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedCOGS[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0);
                });



                COGSObj.items.push(detailedObj);
            }
        }

        // Opening Stock
        const openingStockObj: {
            total: number,
            particular?: string | undefined,
        } = {
            particular: 'Opening Stocks',
            total: 0,
        };

        allMonthYears.forEach((date) => {

            const obj = openingStocks.find((card) => {
                return card.text ===
                    moment('15 ' + date.replace('_', ' ')).add(-1, 'month').format('MMM YY');
            });

            if (obj) {
                openingStockObj[(date).replace(/\s+/g, '_')] = obj.sum as number;
            } else {
                openingStockObj[(date).replace(/\s+/g, '_')] = 0;
            }
        });

        COGSObj.items.unshift(openingStockObj);

        // Closing Stock
        const closingStockObj: {
            total: number,
            particular?: string | undefined,
        } = {
            particular: 'Closing Stocks',
            total: 0,
        };

        allMonthYears.forEach((date) => {
            const obj = closingStocks.find((card) => {
                return card.text.replace(/\s+/g, '_') === date;
            });

            if (obj) {
                closingStockObj[(date).replace(/\s+/g, '_')] = obj.sum as number;
            } else {
                closingStockObj[(date).replace(/\s+/g, '_')] = 0;
            }
        });

        COGSObj.items.push(closingStockObj);

        newTable.push(COGSObj);

        // Gross Profit
        const grossProfitObj: {
            total: number,
            particular?: string | undefined,
        } = {
            total: 0,
        };

        grossProfitObj.particular = 'Gross Profit';

        grossProfitObj.total = grossProfit.reduce((a, b) => {
            return a + b.sum;
        }, 0) * -1;

        grossProfit.forEach((s) => {
            const openingStockFound = openingStocks.find((bal) => {
                return bal.text
                    === moment('15 ' + s.text.replace('_', ' ')).add(-1, 'month').format('MMM YY');
            });

            const closingStockFound = closingStocks.find((bal) => {
                return bal.text === s.text;
            });

            grossProfitObj[(s.text).replace(/\s+/g, '_')] = (s.sum * -1);

            if (openingStockFound) {
                grossProfitObj[(s.text).replace(/\s+/g, '_')] -= openingStockFound.sum;
            }

            if (closingStockFound) {
                grossProfitObj[(s.text).replace(/\s+/g, '_')] += closingStockFound.sum;
            }
        });

        newTable.push(grossProfitObj);


         // Other Income
        const otherIncomeObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        otherIncomeObj.particular = 'Other Income';

        otherIncomeObj.total = otherIncome.reduce((a, b) => {
            return a + b.sum;
        }, 0) * -1;

        otherIncome.forEach((s) => {
            otherIncomeObj[(s.text).replace(/\s+/g, '_')] = s.sum * -1;
        });

        const groupedOI = nestedGroupBy(
            await (await otherIncomeRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedOI) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedOI[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0) * -1;

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedOI[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0) * -1;
                });

                otherIncomeObj.items.push(detailedObj);
            }
        }

        newTable.push(otherIncomeObj);

        // Extra Ordinary Income
        const extraOrdinaryIncomeObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        extraOrdinaryIncomeObj.particular = 'Extra Ordinary Income';

        extraOrdinaryIncomeObj.total = extraOrdinaryIncome.reduce((a, b) => {
            return a + b.sum;
        }, 0);

        extraOrdinaryIncome.forEach((s) => {
            extraOrdinaryIncomeObj[(s.text).replace(/\s+/g, '_')] = s.sum;
        });

        const groupedEO = nestedGroupBy(
            await (await extraOrdinaryIncomeRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedEO) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedEO[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0);

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedEO[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0);
                });

                extraOrdinaryIncomeObj.items.push(detailedObj);
            }
        }

        newTable.push(extraOrdinaryIncomeObj);

        // Expenses
        const expensesObj: {
            total: number,
            items: any[],
            particular?: string | undefined,
        } = {
            total: 0,
            items: [],
        };

        expensesObj.particular = 'Expenses';

        expensesObj.total = expenses.reduce((a, b) => {
            return a + b.sum;
        }, 0);

        expenses.forEach((s) => {
            expensesObj[(s.text).replace(/\s+/g, '_')] = s.sum;
        });

        const groupedExp = nestedGroupBy(
            await (await expensesRef.get()).getCards()
            , ['specialAccTypeName'],
        );

        for (const group in groupedExp) {
            if (group) {

                const detailedObj = {
                    total: 0,
                    particular: group,
                };

                detailedObj.total = groupedExp[group].reduce((a, b) => {
                    return a + b.amount;
                }, 0);

                allMonthYears.forEach((monthYear) => {
                    detailedObj[monthYear] = groupedExp[group].filter((x: any) => {
                        return moment(x.date).format('MMM_YY') === monthYear;
                    }).reduce((a, b) => {
                        return a + b.amount;
                    }, 0);
                });

                expensesObj.items.push(detailedObj);
            }
        }

        newTable.push(expensesObj);

        // Net Profit
        const netProfitObj: {
            total: number,
            particular?: string | undefined,
        } = {
            total: 0,
        };

        netProfitObj.particular = 'Net Profit';

        netProfitObj.total = netProfit.reduce((a, b) => {
            return a + b.sum;
        }, 0) * -1;

        netProfit.forEach((s) => {
            netProfitObj[(s.text).replace(/\s+/g, '_')] = (s.sum) * -1;

            const openingStockFound = openingStocks.find((bal) => {
                return bal.text
                    === moment('15 ' + s.text.replace('_', ' ')).add(-1, 'month').format('MMM YY');
            });

            const closingStockFound = closingStocks.find((card) => {
                return card.text === s.text;
            });

            if (openingStockFound) {
                netProfitObj[(s.text).replace(/\s+/g, '_')] -= openingStockFound.sum;
            }

            if (closingStockFound) {
                netProfitObj[(s.text).replace(/\s+/g, '_')] += closingStockFound.sum;
            }
        });

        // Add in opening and closing total
        closingStockObj.total = closingStocks.reduce((a, b) => a + b.sum, 0);

        COGSObj.total -= closingStockObj.total;
        grossProfitObj.total += closingStockObj.total;
        netProfitObj.total += closingStockObj.total;

        openingStockObj.total = openingStocks.reduce((a, b) => a + b.sum, 0);

        COGSObj.total += openingStockObj.total;
        grossProfitObj.total -= openingStockObj.total;
        netProfitObj.total -= openingStockObj.total;

        newTable.push(netProfitObj);
        this.tableItems = newTable;

        this.tableFields = allMonthYears.map((m) => {
            return {
                key: m,
                sortable: false,
                formatter: TableItemFormatter.currency,
            };
        });

        this.tableFields.unshift({
            key: 'total',
            sortable: false,
            formatter: TableItemFormatter.currency,
        });

        this.tableFields.unshift({
            key: 'particular',
            sortable: false,
        });

        this.detailFields = allMonthYears.map((m) => {
            return {
                key: m,
                sortable: false,
                formatter: TableItemFormatter.currency,
            };
        });

        this.detailFields.unshift({
            key: 'total',
            sortable: false,
            formatter: TableItemFormatter.currency,
        });

        this.detailFields.unshift({
            key: 'particular',
            sortable: false,
        });

        // this.saveHistory(
        //     'tableItems',
        //     'tableFields',
        //     'detailFields',
        // );
    }
}
