
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StackedBarChart from './StackedBarChart.vue';
import * as echarts from 'echarts';
import moment from 'moment';
import { DataViewFormatter } from './helpers/dataViewFormatter';
import gdbx from '@/store/modules/gdbx';
import { TooltipFormatter } from './helpers/tooltipFormatter';
import { GobiColor } from '@/helpers/color';

@Component({
	components: {
		StackedBarChart,
	},
})
export default class WaterfallChartHandler extends Vue {
	@Prop({ default: () => [] }) public readonly xAxisData!: string[];
	@Prop({ default: () => [] }) public readonly xAxisValue!: number[];
	@Prop({ default: () => [] }) public readonly dataColor!: string[];
	@Prop({ default: () => [] }) public readonly barType!: string[];
	@Prop({ default: 'Title' }) public readonly title!: string;

	public emptyData: number[] = [];
	public part1Data: Array<{ value: number; itemStyle: { color: string } }> = [];
	public part2Data: Array<{ value: number; itemStyle: { color: string } }> = [];

	public get dataViewFormatter() {
		return DataViewFormatter.GLProfitAndLoss;
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get formatter() {
		return TooltipFormatter.GLProfitAndLoss;
	}
	@Watch('xAxisValue')
	public onXAxisValueChange() {
		this.calculation();
	}
	public calculation() {
		// let value = 0;

		const emptyData: number[] = [];
		const part1Data: number[] = [];
		const part2Data: number[] = [];

		const colors = this.dataColor;

		let before = 0;
		let after = 0;

		for (let i = 0; i < this.barType.length; i++) {
			const barType = this.barType[i];
			const value = this.xAxisValue[i];
			if (barType === 'static' || i === 0) {
				emptyData.push(0);
				part1Data.push(value);
				part2Data.push(0);
				before = value;
			} else {
				after = before + value;
				if (after * before < 0) {
					emptyData.push(0);
					part1Data.push(before);
					part2Data.push(after);
				} else {
					part2Data.push(0);
					if (before > 0) {
						emptyData.push(Math.min(before, after));
						part1Data.push(Math.abs(value));
					} else if (before === 0) {
						emptyData.push(0);
						part1Data.push(value);
					} else {
						emptyData.push(Math.max(before, after));
						part1Data.push(Math.abs(value) * -1);
					}
				}
				before = before + value;
			}
		}
		this.emptyData = emptyData;
		this.part1Data = part1Data.map((value, i) => ({
			value,
			itemStyle: {
				color:
					this.barType[i] === 'float'
						? GobiColor.COLOR1
						: value < 0
						? GobiColor.COLOR3
						: GobiColor.COLOR5,
			},
		}));
		this.part2Data = part2Data.map((value, i) => ({
			value,
			itemStyle: {
				color:
					this.barType[i] === 'float'
						? GobiColor.COLOR1
						: value < 0
						? GobiColor.COLOR3
						: GobiColor.COLOR5,
			},
		}));
		return [emptyData, part1Data, part2Data];
	}

	public get series(): echarts.ECharts {
		const empty: echarts.ECharts | any = {
			type: 'bar',
			stack: 'GL',
			itemStyle: {
				normal: {
					borderColor: 'rgba(0,0,0,0)',
					color: 'rgba(0,0,0,0)',
				},
				emphasis: {
					borderColor: 'rgba(0,0,0,0)',
					color: 'rgba(0,0,0,0)',
				},
			},
			data: this.emptyData,
		};

		const part1: echarts.ECharts | any = {
			name: 'part 1',
			type: 'bar',
			stack: 'GL',
			data: this.part1Data,
		};

		const part2: echarts.ECharts | any = {
			name: 'part 2',
			type: 'bar',
			stack: 'GL',
			data: this.part2Data,
		};
		const factor: echarts.ECharts | any = {
			name: 'factor',
			type: 'bar',
			stack: 'GL',
			data: this.xAxisValue.map((value) =>
				value < 0 ? -1 : value > 0 ? 1 : 0,
			),
		};

		const result: echarts.ECharts | any = [
			empty,
			part1,
			part2,
			factor,
		];

		return result;
	}
}
