
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ListFilter from './ListFilter.vue';
import ListFilterContainer from './ListFilterContainer.vue';
import gdbx from '@/store/modules/gdbx';

@Component({
    components: {
        ListFilter,
    },
})
export default class AgentsListFilter extends ListFilterContainer {
    @Prop({ default: false }) public isShowCode!: boolean;

    public includeInactive = true;
    public isIncludeInactive(event: boolean) {
        this.includeInactive = event;
    }

    public get options() {
        if (this.includeInactive) {
            return gdbx.allProjects;
        } else {
            return gdbx.allProjects.filter((x) => {
                return !x.disabled;
            });
        }
    }
}
