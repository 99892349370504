
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import WaterfallChartFilterable from '@/components/reports-v2/components/filterables/WaterfallChartFilterable.vue';
import moment, { Moment } from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		WaterfallChartFilterable,
	},
})
export default class ProfitAndLostBarChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
    | 'projects'
	> = ['dateRange'];

	public xAxisData = [
		'NET SALES',
		'COGS',
		'GROSS PROFIT/LOSS',
		'OTHER INCOME',
		'EXPENSES',
		'NET PROFIT/LOSS BEFORE TAX',
	];
	public barType: string[] = [
		'static',
		'float',
		'static',
		'float',
		'float',
		'static',
		'float',
		'static',
	];
	public dataColor: string[] = [
		GobiColor.COLOR1,
		GobiColor.COLOR2,
		GobiColor.COLOR3,
		GobiColor.COLOR4,
		GobiColor.COLOR5,
		GobiColor.COLOR6,
		GobiColor.COLOR7,
	];
	public xAxisValue: number[] = [];
	// public series: echarts.ECharts[] | any = [];

	public get dateFormatted() {
		return this.selectedDateRange;
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get monthValues() {
		const [d0, d1] = this.selectedDateRange.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		const result: Moment[] = [];
		for (let i = 0; i < period; i++) {
			result.push(d0.clone());
			d0.add(1, 'month');
		}
		return result;
	}

	public get expensiveHook() {
		const {
      selectedDateRange,
      monthValues,
      selectedProjects,
    } = this;
		return JSON.stringify([
      selectedDateRange,
      monthValues,
      selectedProjects,
    ]);
	}

    public openingStockDateRange(dateRange: [number, number]): [number, number] {
        return [
            moment(dateRange[0]).add(-1, 'month').valueOf(),
            moment(dateRange[1]).add(-1, 'month').valueOf(),
        ];
    }

	public async expensiveCalc() {
        const netSalesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA']);

        const COGSRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['CO']);

        const grossProfitRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA', 'CO']);

        const otherIncomeRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['OI']);

        const extraOrdinaryIncomeRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['EO']);

        const expensesRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['EP']);

        const netProfitRef = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects)
            .includes('accType', ['SL', 'SA', 'CO', 'OI', 'EO', 'EP']);

        const openingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.openingStockDateRange(this.selectedDateRange));

        const closingStockBalanceRef = FilteredDatabase.ref('stockBalances')
            .includes('project', this.selectedProjects)
            .dateRange(this.selectedDateRange);

        this.generateLoadingText(1);

        let pb = 0.1;
        let pa = 0.1;

        const netSales = await this._loadDimensionByPeriod(
            netSalesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const COGS = await this._loadDimensionByPeriod(
            COGSRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const grossProfit = await this._loadDimensionByPeriod(
            grossProfitRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const otherIncome = await this._loadDimensionByPeriod(
            otherIncomeRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const extraOrdinaryIncome = await this._loadDimensionByPeriod(
            extraOrdinaryIncomeRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const expenses = await this._loadDimensionByPeriod(
            expensesRef,
            'month',
            pa,
            pb,
            'amount',
        );

        pb += pa;
        pa = 0.1;

        const netProfit = await this._loadDimensionByPeriod(
            netProfitRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const openingStocks = await this._loadDimensionByPeriod(
            openingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const closingStocks = await this._loadDimensionByPeriod(
            closingStockBalanceRef,
            'month',
            pa,
            pb,
            'amount',
        );

        const openingStocksTotal = openingStocks.reduce((a, b) => a + b.sum, 0);
        const closingStocksTotal = closingStocks.reduce((a, b) => a + b.sum, 0);

        const actualGrossProfit = grossProfit
            .reduce((a, b) => a + b.sum, 0) * -1
            - openingStocksTotal
            + closingStocksTotal;
        const actualNetProfit = netProfit
            .reduce((a, b) => a + b.sum, 0) * -1
            - openingStocksTotal
            + closingStocksTotal;

		this.xAxisValue = [
			netSales.reduce((a, b) => a + b.sum, 0) * -1,
			COGS.reduce((a, b) => a + b.sum, 0),
            actualGrossProfit,
			otherIncome.reduce((a, b) => a + b.sum, 0) * -1,
			expenses.reduce((a, b) => a + b.sum, 0),
            actualNetProfit,
		];

		this.saveHistory('xAxisData', 'xAxisValue');
	}
}
