
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
    DimensionData,
    DimensionMap,
    TransactionCard,
    PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import TableCollapseToggle from '@/components/TableCollapseToggle.vue';
import moment from 'moment';

@Component({
    components: {
        TableFilterable,
        TableCollapseToggle,
    },
})
export default class ExpensesTable extends FilterWidget {
    public get permissionIds(): PermissionsGroup[] {
        return ['transactions'];
    }

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'projects'
    > = ['dateRange'];

    public tableItems: any[] = [];
    public tableFields: any[] = [];
    public periodTotalData: any[] = [];
    public periodTotalFields: any[] = [];

    public get exportFileName() {
        const formattedDate = [
            moment(this.selectedDateRange[0]).format('DD MMM YY'),
            moment(this.selectedDateRange[1]).format('DD MMM YY'),
        ];
        return (
            'Expenses' + '_' + formattedDate[0] + ' to ' + formattedDate[1]
        );
    }

    public get dateFormatted() {
        return this.selectedDateRange;
    }

    public get currencySymbol() {
        return gdbx.currencySymbol;
    }

    public get numDecimal() {
        return gdbx.numDecimal;
    }

    public get expensiveHook() {
        const {
          selectedDateRange,
          selectedProjects,
        } = this;
        return JSON.stringify([
          selectedDateRange,
          selectedProjects,
        ]);
    }

    public async expensiveCalc() {
      const ref = FilteredDatabase.ref('transactions')
              .dateRange(this.selectedDateRange)
              .includes('project', this.selectedProjects);

        let pa = 0.2;
        let pb = 0;

        const refHead = await ref.get();

        const cards: TransactionCard[] = await refHead.getCards();

        const coCards = cards.filter((item) => item.accType === 'CO' && item.specialAccType !== 'MC');

        const mcCards = cards.filter((item) => item.accType === 'CO' && item.specialAccType === 'MC');

        const specialAccTypeCodeListCO = [
            ...new Map(
                coCards.map((item) => [
                    item.specialAccTypeCode!,
                    item.specialAccTypeName,
                ]),
            ).entries(),
        ];

        const specialAccTypeCodeListMC = [
            ...new Map(
                mcCards.map((item) => [
                    item.specialAccTypeCode!,
                    item.specialAccTypeName,
                ]),
            ).entries(),
        ];

        const specialAccTypeCodeMapCO: DimensionMap[] = specialAccTypeCodeListCO.map(
            (item) => ({
                filterType: 'string',
                filterKey: 'specialAccTypeCode',
                value: [item[0]],
                text: item[1],
            }),
        );

        pb += pa;
        pa = 0.125;

        const specialAccTypeCodeMapMC: DimensionMap[] = specialAccTypeCodeListMC.map(
            (item) => ({
                filterType: 'string',
                filterKey: 'specialAccTypeCode',
                value: [item[0]],
                text: item[1],
            }),
        );

        pb += pa;
        pa = 0.125;

        const monthDatas: DimensionData[] = await this._loadDimensionByPeriod(
            ref,
            'month',
            pa,
            pb,
        );

        pb += pa;
        pa = 0.125;

        const specialAccTypeDatasCO: DimensionData[] = await this._loadDimension(
            ref,
            specialAccTypeCodeMapCO,
            pa,
            pb,
        );

        const specialAccTypeDatasMC: DimensionData[] = await this._loadDimension(
            ref,
            specialAccTypeCodeMapMC,
            pa,
            pb,
        );

        this.tableFields = [
            { key: 'type', sortable: true, stickyColumn: true },
            { key: 'particular', sortable: true, stickyColumn: true },
            {
                key: 'total',
                sortable: true,
                formatter: TableItemFormatter.currency,
            },
            ...monthDatas.map((dd) => ({
                key: dd.text,
                sortable: true,
                formatter: TableItemFormatter.currency,
            })),
        ];

        this.periodTotalFields = [
            { key: 'type', sortable: true },
            { key: 'total', sortable: true, formatter: TableItemFormatter.currency },
        ];

        pb += pa;
        pa = 0.125;

        const datas = await this._loadDimension(
            ref,
            specialAccTypeCodeMapCO,
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(reff, monthDatas, paa, pbb, 'amount'),
        );

        pb += pa;
        pa = 0.125;

        const datas2 = await this._loadDimensionByPeriod(
            ref,
            'month',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(reff, specialAccTypeDatasCO, paa, pbb, 'amount'),
        );

        const datas3 = await this._loadDimension(
            ref,
            specialAccTypeCodeMapMC,
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(reff, monthDatas, paa, pbb, 'amount'),
        );

        pb += pa;
        pa = 0.125;

        const datas4 = await this._loadDimensionByPeriod(
            ref,
            'month',
            pa,
            pb,
            (reff, paa, pbb) =>
                this._loadDimension(reff, specialAccTypeDatasMC, paa, pbb, 'amount'),
        );

        this.generateLoadingText(1);

        const periodTotalDataCO: Array<{ month: number; total: number[] }> = [];

        const periodTotalDataMC: Array<{ month: number; total: number[] }> = [];

        const cloneDatas2 = [...datas2];
        const subDDListCO = cloneDatas2.map((datas2dd) => datas2dd!.subDimension);

        const cloneDatas4 = [...datas4];
        const subDDListMC = cloneDatas4.map((datas4dd) => datas4dd!.subDimension);

        const coTable = datas
            // .filter((dd) => dd.sum !== 0)
            .map((dd, index) => {
                const item = {
                    type: 'COGS',
                    particular: dd.text,
                    total: dd.sum,
                    _cellVariants: {},
                };
                let i = 0;

                dd.subDimension!.forEach((sd, sdIndex) => {
                    i++;
                    const result = subDDListCO[sdIndex]!.sort((a, b) => b.sum - a.sum)
                        .filter((subdd) => subdd.sum !== 0)
                        .slice(0, 5)
                        .map((sumListItem) => sumListItem.sum);

                    const includeTopFive = result.includes(sd.sum);

                    item[sd.text] = sd.sum;
                    item._cellVariants[sd.text] = includeTopFive ? 'top-five' : '';

                    const subItem: any = { month: sd.value[0][1], total: [] };

                    if (periodTotalDataCO[sdIndex]) {
                        if (i < 1) {
                            periodTotalDataCO[sdIndex].month = sd.value[0][1] as number;
                        }
                        periodTotalDataCO[sdIndex].total[index] = roundToFixed(
                            sd.sum,
                            this.numDecimal,
                        );
                    } else {
                        periodTotalDataCO[sdIndex] = subItem;
                        if (i < 1) {
                            periodTotalDataCO[sdIndex].month = sd.value[0][1] as number;
                        }
                        periodTotalDataCO[sdIndex].total[index] = roundToFixed(
                            sd.sum,
                            this.numDecimal,
                        );
                    }
                });
                return item;
            });

        const mcTable = datas3
            // .filter((dd) => dd.sum !== 0)
            .map((dd, index) => {
                const item = {
                    type: 'MFGC',
                    particular: dd.text,
                    total: dd.sum,
                    _cellVariants: {},
                };
                let i = 0;

                dd.subDimension!.forEach((sd, sdIndex) => {
                    i++;
                    const result = subDDListMC[sdIndex]!.sort((a, b) => b.sum - a.sum)
                        .filter((subdd) => subdd.sum !== 0)
                        .slice(0, 5)
                        .map((sumListItem) => sumListItem.sum);

                    const includeTopFive = result.includes(sd.sum);

                    item[sd.text] = sd.sum;
                    item._cellVariants[sd.text] = includeTopFive ? 'top-five' : '';

                    const subItem: any = { month: sd.value[0][1], total: [] };

                    if (periodTotalDataMC[sdIndex]) {
                        if (i < 1) {
                            periodTotalDataMC[sdIndex].month = sd.value[0][1] as number;
                        }
                        periodTotalDataMC[sdIndex].total[index] = roundToFixed(
                            sd.sum,
                            this.numDecimal,
                        );
                    } else {
                        periodTotalDataMC[sdIndex] = subItem;
                        if (i < 1) {
                            periodTotalDataMC[sdIndex].month = sd.value[0][1] as number;
                        }
                        periodTotalDataMC[sdIndex].total[index] = roundToFixed(
                            sd.sum,
                            this.numDecimal,
                        );
                    }
                });
                return item;
            });

        this.tableItems = coTable.concat(mcTable);

        const coPeriodTable = coTable.map((item) => {
            return {
                total: item.total,
            };
        });

        const mcPeriodTable = mcTable.map((item) => {
            return {
                total: item.total,
            };
        });

        const periodDataCO = [{
            type: 'COGS',
            total: coPeriodTable.reduce((a, b) => a + b.total, 0),
        }];

        const periodDataMC = [{
            type: 'Manufacturing Costs',
            total: mcPeriodTable.reduce((a, b) => a + b.total, 0),
        }];

        this.periodTotalData = periodDataCO.concat(periodDataMC);

        // let periodDataMC = mcPeriodTable.map((item) => ({
        //     month: item.month,
        //     total: item.total.reduce((a, b) => a + b, 0),
        // }));

        this.saveHistory(
            'tableItems',
            'tableFields',
            'periodTotalData',
            'periodTotalFields',
        );
    }
}
