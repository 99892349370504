
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import LastSync from '@/components/LastSync.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import gdbx from '@/store/modules/gdbx';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import TotalRevenueNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/TotalRevenueNumberDisplayer.vue';
import TotalCOGSNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/TotalCOGSNumberDisplayer.vue';
import TotalGrossProfitNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/TotalGrossProfitNumberDisplayer.vue';
import TotalExpenseNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/TotalExpenseNumberDisplayer.vue';
import NetProfitBeforeTaxNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/NetProfitBeforeTaxNumberDisplayer.vue';
import ProfitAndLostBarChart from '@/components/reports-v2/components/codedWidgets/gl/ProfitAndLostBarChart.vue';
import SalesPurchaseExpenseBarChart from '@/components/reports-v2/components/codedWidgets/gl/SalesPurchaseExpenseBarChart.vue';
import ExpensesTable from '@/components/reports-v2/components/codedWidgets/gl/ExpensesTable.vue';
import ProfitAndLossComparisonMixedChart from '@/components/reports-v2/components/codedWidgets/gl/ProfitAndLossComparisonMixedChart.vue';
import COGSTable from '@/components/reports-v2/components/codedWidgets/gl/COGSTable.vue';
import TotalOtherIncomeNumberDisplayer from '@/components/reports-v2/components/codedWidgets/bp/TotalOtherIncomeNumberDisplayer.vue';
import ProjectRadioFilter from '@/components/reports-v2/components/filters/ProjectRadioFilter.vue';
import accountx from '@/store/modules/accountx';
import ProjectSelectFilter from '@/components/reports-v2/components/filters/ProjectSelectFilter.vue';
import MonthToMonthComparison from '@/components/reports-v2/components/codedWidgets/gl/MonthToMonthComparison.vue';

@Component({
	components: {
        MonthToMonthComparison,
        ProjectSelectFilter,
        ProjectRadioFilter,
		TotalOtherIncomeNumberDisplayer,
		COGSTable,
		StickyFilterContainer,
		DateRangeFilter,
		LastSync,
		SelectFilter,
		TotalRevenueNumberDisplayer,
		TotalCOGSNumberDisplayer,
		TotalGrossProfitNumberDisplayer,
		ProfitAndLostBarChart,
		TotalExpenseNumberDisplayer,
		NetProfitBeforeTaxNumberDisplayer,
		ExpensesTable,
		SalesPurchaseExpenseBarChart,
		ProfitAndLossComparisonMixedChart,
	},
})
export default class ProfitAndLoss extends Vue {
	public selectedDay: number = moment().endOf('day').valueOf();
	public selectedDateRange: [number, number] = [
		moment().startOf('year').valueOf(),
		moment().endOf('day').valueOf(),
	];

  public selectedProjects: string[] = [];

  public get selectedYearToDateRange(): [number, number] {
    return [
      moment(this.selectedDateRange[1]).add(-11, 'month').startOf('month').valueOf(),
      moment(this.selectedDateRange[1]).endOf('month').valueOf(),
    ];
  }

  public get getAccountId() {
    return accountx.currentAccountId;
  }

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('month').valueOf();
	}

	public get selectedAgents() {
		return gdbx.allAgents;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
