
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {
	DimensionData,
	DimensionMap,
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class TotalExpenseNumberDisplayer extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
    | 'project'
	> = ['dateAsOf'];

	public amount: number = 0;

	public get accountStartingPeriodThisYear(): [number, number] {
		return [gdbx.accountStartingDateThisYear, moment().endOf('day').valueOf()];
	}

	public get expensiveHook() {
		const {
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
      selectedProjects,
		} = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedDateRange,
			accountStartingPeriodThisYear,
      selectedProjects,
		]);
	}

	public async expensiveCalc() {
    const ref = FilteredDatabase.ref('transactions')
            .dateRange(this.selectedDateRange)
            .includes('project', this.selectedProjects);

		const expensesMap: DimensionMap[] = [
			{
				filterType: 'string',
				filterKey: 'accType',
				value: ['EP'],
				text: 'EXPENSES',
			},
		];

		const expenseDatas: DimensionData[] = await this._loadDimension(
			ref,
			expensesMap,
			1,
			0,
			'amount',
		);

		const totalExpenses = expenseDatas.reduce((a, b) => a + b.sum, 0);

		this.amount = totalExpenses;

		this.saveHistory('amount');
	}
}
